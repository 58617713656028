import styled from 'styled-components';

export const ImageElement = styled.img`
  width: 100%;
  transition: all 0.3s ease;
  opacity: ${({ isVisible }) => (isVisible ? 1.0 : 0.0)};
  opacity: ${({ hasBeenVisible }) => (hasBeenVisible ? 1.0 : 0.0)};
  transform: translateY(${({ isVisible }) => (isVisible ? ' 0%' : ' 2%')});
  transform: translateY(
    ${({ hasBeenVisible }) => (hasBeenVisible ? ' 0%' : ' 2%')}
  );
  border-radius: 4px;
`;
