import React from 'react';
import PropTypes from 'prop-types';
import IO from 'components/io';
import { ImageElement } from './image.css';

const Image = ({ filename, alt, size }) => {
  return (
    <IO rootMargin="-50px">
      {({ isVisible, hasBeenVisible }) => (
        <ImageElement
          srcset={
            '/img/' +
            filename +
            '?nf_resize=fit&w=' +
            size +
            ', /img/' +
            filename +
            '?nf_resize=fit&w=' +
            size * 2 +
            ' 2x'
          }
          src={'/img/' + filename + '?nf_resize=fit&w=' + size * 2}
          alt={alt}
          hasBeenVisible={hasBeenVisible}
          isVisible={isVisible}
        />
      )}
    </IO>
  );
};

Image.propTypes = {
  filename: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
};

export default Image;
